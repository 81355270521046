
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700&display=swap');
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body * {
  font-family: 'Public Sans', sans-serif;
  font-weight: 400;
}
body b {
  font-weight: bold;
}
body.root {
  overflow: hidden;
}

body .ol-dragzoom {
  border: 2px dashed #747F9F;
  background-color: rgba(255,255,255,0.3);
}

body .ol-overlaycontainer-stopevent {
  z-index: 30 !important;
}

body .ol-overlaycontainer-stopevent .rightmenu--overlay{
  z-index: 999999;
}

body .ol-overlaycontainer-stopevent .ol-search {
  background: transparent;
  padding: 0;
  position: absolute;
  top: auto;
  right: 0.5rem;
  bottom: calc(5rem + 42px);
  left: auto;
}

body .ol-overlaycontainer-stopevent .ol-search.hide {
  display: none;
}

body .ol-overlaycontainer-stopevent .ol-control {
  background-color: rgba(255, 255, 255, 0.65);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: absolute;
  top: auto;
  right: 1.5rem;
  bottom: 7.5rem;
  left: auto;
}

body .ol-overlaycontainer-stopevent .ol-control.ol-overviewmap {
  background: #F1F4FC;
  border-radius: 8px;
  box-shadow: 0 6px 8px 0 #BFD0DA;
}

.ol-overviewmap .ol-overviewmap-map {
  width: 166px;
  height: 106px;
  border-radius: 8px;
  border: none;
}

.ol-overviewmap-map canvas {
  border-radius: 8px;
}

.ol-overviewmap-map .ol-overviewmap-box {
  max-width: 154px;
  max-height: 94px;
  background-color: rgba(115, 207, 154, 0.3);
  border: 2px solid #73cf9a;
}

body .ol-overlaycontainer-stopevent .ol-control.gc-search {
  top: 0.5rem;
  right: 0.5rem;
  left: auto;
}

body .ol-overlaycontainer-stopevent .ol-control button:focus,
body .ol-overlaycontainer-stopevent .ol-control button:hover {
  background-color: white;
}

body .ol-overlaycontainer-stopevent button {
  width: 32px;
  height: 32px;
  align-items: center;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #002340;
  border-radius: 50% !important;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 9px;
  font-weight: normal;
  justify-content: center;
  line-height: 12px;
  margin: 0 auto;
  outline: 0;
  transition: background-color 0.2 ease-out;
  -o-transition: background-color 0.2 ease-out;
  -moz-transition: background-color 0.2 ease-out;
  -webkit-transition: background-color 0.2 ease-out;
}
body .ol-overlaycontainer-stopevent button + button {
  margin-top: 8px;
}

body .ol-overlaycontainer-stopevent button:hover {
  box-shadow: 0px 0px 2px 3px rgba(0, 163, 249, 0.1);
}

body .ol-mouse-custom {
  width: auto;
  height: 24px;
  top: 0;
  padding-top: 4px;
}

body .ol-overlaycontainer-stopevent .ol-scale-line {
  /* left: calc(100% - 190px); */
  left: 170px;
  bottom: 0px;
  background-color: transparent;
  position: fixed;
  z-index: 30;
}

body .ol-overlaycontainer-stopevent .ol-scale-line .ol-scale-line-inner {
  width: auto;
  /* max-width: 173px; */
  border: 10px solid;
  border-image-slice: 1;
  border-width:1px;
  border-image-source: linear-gradient(to bottom, #1A1E23 50%, #FFFFFF 50%);
  color: #fff;
  font-size: 12.8px;
  padding-right: 10px;
  padding-bottom: 2px;
  text-align: right;
}

body::-webkit-scrollbar {
  width: 16px;
  background-color: #fff;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

body::-webkit-scrollbar-button {
  display: none;
}

strong {
  font-weight: 700;
}
